import { ProductCardType } from '@/constants'
import { useStore } from '@/containers'

interface SalePriceType {
  type?: string
  classes?: string
  finalPrice: number
}

export const SalePrice = ({ finalPrice, classes, type }: SalePriceType) => {
  const { selectedLang } = useStore()
  const currency = (lang: string) => {
    switch (lang) {
      case 'vi':
        return 'VND'
      case 'en':
        return 'USD'
      default:
        return 'VND'
    }
  }

  return (
    <p
      className={`font-extrabold leading-tight ${classes ? classes : ''} ${
        type == ProductCardType.PRODUCT_DETAIL ? 'text-3xl' : 'text-2xl'
      }  `}
    >
      {finalPrice &&
        finalPrice
          .toLocaleString(selectedLang as string, {
            style: 'currency',
            currency: currency(selectedLang as string),
          })
          .replace(/\s₫/g, '')}
      {type == ProductCardType.PRODUCT_DETAIL ? (
        <span className={`ml-[2px] text-2xl font-bold leading-none`}>đ</span>
      ) : (
        <sup className={`text-sm font-bold`}>đ</sup>
      )}
    </p>
  )
}

export default SalePrice
