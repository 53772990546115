import { LabelPosition } from './common'

export const labelPositionMapStyle = new Map()
  .set(LabelPosition.TOP_LEFT, 'top-0 left-0 max-w-[25%]')
  .set(
    LabelPosition.TOP_MID,
    'top-0 left-[50%] translate-x-[-50%] w-max max-w-[50%]'
  )
  .set(LabelPosition.TOP_RIGHT, 'top-0 right-0 max-w-[25%]')
  .set(
    LabelPosition.MID_RIGHT,
    'top-[50%] translate-y-[-50%] right-0 max-w-[25%]'
  )
  .set(
    LabelPosition.MID_LEFT,
    'top-[50%] translate-y-[-50%] left-0 max-w-[25%]'
  )
  .set(LabelPosition.BOTTOM_LEFT, 'bottom-0 left-0 max-w-[100%]')
  .set(
    LabelPosition.BOTTOM_MID,
    'bottom-0 left-[50%] translate-x-[-50%] w-max max-w-[100%]'
  )
  .set(LabelPosition.BOTTOM_RIGHT, 'bottom-0 right-0 max-w-[100%]')

export const labelPositionProductCard = new Map()
  .set(LabelPosition.TOP_LEFT, 'top-0 left-0 max-w-[25%]')
  .set(
    LabelPosition.TOP_MID,
    'top-0 left-[50%] translate-x-[-50%] w-max max-w-[50%]'
  )
  .set(LabelPosition.TOP_RIGHT, 'top-0 right-0 max-w-[25%]')
  .set(
    LabelPosition.MID_RIGHT,
    'top-[50%] translate-y-[-50%] right-0 max-w-[25%]'
  )
  .set(
    LabelPosition.MID_LEFT,
    'top-[50%] translate-y-[-50%] left-0 max-w-[25%]'
  )
  .set(LabelPosition.BOTTOM_LEFT, 'bottom-0 left-0 max-w-[100%]')
  .set(
    LabelPosition.BOTTOM_MID,
    'bottom-0 left-[50%] translate-x-[-50%] w-max max-w-[100%]'
  )
  .set(LabelPosition.BOTTOM_RIGHT, 'bottom-0 right-0 max-w-[100%]')
