import { PageType } from '@/constants'
import { useStore } from '@/containers'

interface OriginPriceType {
  type?: string
  classes?: string
  originPrice: number
}

export const OriginPrice = ({
  originPrice,
  classes,
  type,
}: OriginPriceType) => {
  const { selectedLang } = useStore()
  const currency = (lang: string) => {
    switch (lang) {
      case 'vi':
        return 'VND'
      case 'en':
        return 'USD'
      default:
        return 'VND'
    }
  }
  return (
    <div
      className={`${
        type == PageType.PRODUCT ? 'text-dark-700' : 'text-sm text-dark-700'
      } ${type == 'product-detail' ? '!text-base' : ''} ${
        classes ? classes : ''
      } originPrice cursor-pointer leading-4 line-through`}
    >
      {originPrice &&
        originPrice
          .toLocaleString(selectedLang as string, {
            style: 'currency',
            currency: currency(selectedLang as string),
          })
          .replace(/\s₫/g, 'đ')}
    </div>
  )
}

export default OriginPrice
