import { useAuth } from '@/containers'
import Link from 'next/link'
import OriginPrice from './OriginPrice'
import SalePrice from './SalePrice'

interface PriceRelatedType {
  type?: string
  classes?: string
  originPrice: number
  link?: string
  finalPrice: number
}

export const PriceRelated = ({
  type,
  classes,
  originPrice,
  link = '',
  finalPrice,
}: PriceRelatedType) => {
  const { token } = useAuth()
  const percent = Math.ceil(100 - (finalPrice / originPrice) * 100)
  return (
    <div className={`${type ? type : ''} price-wrapper w-full mt-1`}>
      <OriginPrice originPrice={originPrice} classes={classes} type={type} />
      {/* <div className='my-1'>
        {token ? (
          <span className='text-xs font-normal text-dark-400'>
            Giá cho bạn:
          </span>
        ) : (
          <Link
            href='/login'
            className='text-xs font-normal text-dark-400 outline-none block'
          >
            Thành viên:
          </Link>
        )}
      </div> */}
      <div className='sale-price flex items-center justify-between'>
        {token ? (
          <SalePrice
            finalPrice={finalPrice}
            classes={'text-purple font-bold'}
            type={type}
          />
        ) : (
          <Link
            // href='/login'
            href={link}
            className='flex items-center gap-x-1 text-mainBody'
          >
            <SalePrice
              finalPrice={finalPrice}
              classes={'text-purple font-bold'}
              type={type}
            />
          </Link>
        )}
        {percent > 0 && (
          <span className='discount flex items-baseline gap-[2px]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='10'
              height='10'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                d='M14.0004 6C13.7352 6 13.4808 6.10536 13.2933 6.29289C13.1058 6.48043 13.0004 6.73478 13.0004 7V11.586L2.70741 1.293C2.5188 1.11084 2.2662 1.01005 2.00401 1.01233C1.74181 1.0146 1.491 1.11977 1.30559 1.30518C1.12018 1.49059 1.01501 1.7414 1.01273 2.0036C1.01045 2.2658 1.11125 2.5184 1.29341 2.707L11.5864 13H7.00041C6.73519 13 6.48084 13.1054 6.2933 13.2929C6.10576 13.4804 6.00041 13.7348 6.00041 14C6.00041 14.2652 6.10576 14.5196 6.2933 14.7071C6.48084 14.8946 6.73519 15 7.00041 15H14.0004C14.2656 15 14.52 14.8946 14.7075 14.7071C14.8951 14.5196 15.0004 14.2652 15.0004 14V7C15.0004 6.73478 14.8951 6.48043 14.7075 6.29289C14.52 6.10536 14.2656 6 14.0004 6Z'
                fill='#FF424E'
              />
            </svg>
            <span className='flex items-center text-percent text-primary-second'>
              <span className='value'>{percent}</span>
              <span className='unit text-xs'>%</span>
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export default PriceRelated
