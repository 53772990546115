interface Props {
  type?: string
  label?: string
}
const ProductLabel = ({ label }: Props) => {
  switch (label) {
    case 'new':
      return (
        <span
          className={`label mr-2 block h-7 w-max items-center justify-center rounded bg-primary-second px-1 text-center font-bold uppercase leading-7 text-white`}
        >
          New
        </span>
      )
    case 'hot':
      return (
        <span
          className={`label mr-2 block h-7 w-max items-center justify-center rounded bg-primary-second px-1 text-center font-bold uppercase leading-7 text-white`}
        >
          hot
        </span>
      )
    case 'comingSoon':
      return (
        <span
          className={`label mr-2 block h-7 w-max items-center justify-center rounded bg-primary-base px-1 text-center font-bold uppercase leading-7 text-white`}
        >
          comming soon
        </span>
      )

    default:
      return <></>
  }
}
export default ProductLabel
