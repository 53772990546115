import { useFlashSale } from '@/containers/FlashSaleContainer'
import { useEffect, useState } from 'react'

interface CountdownType {
  endTime: number
  type: string
  productId: string
  className?: string
}
const Countdown = ({ endTime, type, productId, className }: CountdownType) => {
  const { dispatch } = useFlashSale()
  const savedTime = new Date(endTime)
  const countDownDate = new Date(savedTime).getTime()
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    setDays(Math.floor(countDown / (1000 * 60 * 60 * 24)))
    setHours(
      Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days * 24
      )
    )
    setMinutes(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((countDown % (1000 * 60)) / 1000))
  }, [countDown])
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
      if (countDownDate - new Date().getTime() < 0) {
        setDays(0)
        setHours(0)
        setMinutes(0)
        setSeconds(0)
        setCountDown(0)
        dispatch({
          type: 'DELETE_PRODUCT',
          payload: {
            productId,
          },
        })
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])
  return (
    <div
      className={`countDown z-[49] flex w-full items-center text-base font-bold leading-none text-white ${
        type == 'product-detail'
          ? 'relative h-[46px] justify-center gap-x-[4px]'
          : type == 'flashSaleBlock'
          ? '!text-countdown h-[55px] justify-start gap-1'
          : '!text-countdown absolute bottom-0 left-0 h-[24px] justify-start gap-[3px] px-2'
      } ${className}`}
    >
      {hours < 10 ? (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>0</span>
          <span className={'block leading-none'}>{hours}</span>
        </div>
      ) : hours >= 24 ? (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          {hours % 24 > 10 ? (
            <>
              <span className={'block leading-none'}>
                {`${hours % 24}`?.charAt(0)}
              </span>
              <span className={'block leading-none'}>
                {`${hours % 24}`?.charAt(1)}
              </span>
            </>
          ) : (
            <>
              <span className={'block leading-none'}>0</span>
              <span className={'block leading-none'}>
                {`${hours % 24}`?.charAt(0)}
              </span>
            </>
          )}
        </div>
      ) : (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>{`${hours}`?.charAt(0)}</span>
          <span className={'block leading-none'}>{`${hours}`?.charAt(1)}</span>
        </div>
      )}
      <span
        className={`${
          type == 'flashSaleBlock' || type == 'product-detail'
            ? 'text-3xl font-black'
            : ''
        } delimiter leading-none text-black-400`}
      >
        :
      </span>
      {minutes < 10 ? (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>0</span>
          <span className={'block leading-none'}>{minutes}</span>
        </div>
      ) : (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>
            {`${minutes}`?.charAt(0)}
          </span>
          <span className={'block leading-none'}>
            {`${minutes}`?.charAt(1)}
          </span>
        </div>
      )}
      <span
        className={`${
          type == 'flashSaleBlock' || type == 'product-detail'
            ? 'text-3xl font-black'
            : ''
        } delimiter  text-black-400`}
      >
        :
      </span>
      {seconds < 10 ? (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>0</span>
          <span className={'block leading-none'}>{seconds}</span>
        </div>
      ) : (
        <div
          className={`rounded-lg bg-dark-900 ${
            type == 'product-detail'
              ? 'h-[46px] w-[46px] pt-[4px] text-2xl font-extrabold'
              : type == 'flashSaleBlock'
              ? 'h-[55px] w-[55px] pt-[4px] text-[32px]'
              : 'h-[26px] w-[26px]'
          } formCountTime flex items-center justify-center`}
        >
          <span className={'block leading-none'}>
            {`${seconds}`?.charAt(0)}
          </span>
          <span className={'block leading-none'}>
            {`${seconds}`?.charAt(1)}
          </span>
        </div>
      )}
    </div>
  )
}

export default Countdown
